.wrapper-container{
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    z-index: 1000;
    padding: 1rem;
    background-color: white;
}
