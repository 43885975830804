.active-state {
  transform: scale(1.2);
}

.states .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
}
.states .nav::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.states .nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 3px;
  height: 5px;
}

.states .nav::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  width: 3px;
}
.nav li {
  margin-left: 15px;
}
.wizard-container {
  cursor: pointer;
}
.wizard-container p {
  font-size: 12px;
  line-height: 15px;
}
.wizard-circle {
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: 50%;
  margin: auto;
}

.wizard-circle span {
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  margin: auto;
}
.btn-component {
  display: flex;
  justify-content: space-between;
}
