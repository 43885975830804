@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}


.table-responsive-input 
.form-group{
    margin-bottom: 0rem;
}

.table-responsive-input th .form-group{
    margin-bottom: 1rem;
}
.table-responsive-input 
{
  max-height: 245px;
  overflow: auto;
border: 1px solid #ddd !important;
}
.table-responsive-input 
th {
  text-align: left;
    padding: 0.3rem;
position: sticky; top: 0; z-index: 1;

 background:#eee;
}

.table-responsive-input 
thead {
  vertical-align: middle;
}

.table-responsive-input 
td {
  text-align: left;
    padding: 0.3rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.table-container-input{
  max-height: calc(100vh - 415px) ;
  overflow: auto;
}

.table-container-input-with-total{
  max-height: calc(100vh - 390px) ;
  overflow: auto;
}

.table-container-input-with-title{
  max-height: calc(100vh - 380px) ;
  overflow: auto;
}
.table-container-input-without-title-total
{max-height: calc(100vh - 360px) ;
  overflow: auto;
}
.form-control:disabled, .form-control[readonly] {
    color: $custom-control-label-disabled-color;
    opacity: 0.5;
  }

