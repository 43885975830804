.content-header{
    position: fixed;
    top: 0;
    left: 0;
    height: $content-header-height;
    width: 100%;
    background-color: $content-header-bg;
    z-index: 3;
}
.content-states{
    position: fixed;
    top: $content-header-height;
    height: $content-states-height;
    width: 100%;
    background-color: $content-states-bg;
    z-index: 3;
    left: 0;
}
.action-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: $content-action-height;
    background-color: $content-action-bg;
    z-index: 3;
}
.action-header {
    position: fixed;
    top: $content-header-height+$content-states-height;
    left: 0;
    height: $content-action-height;
    width: 100%;
    background-color: $content-action-bg;
    z-index: 3;
}
.content-content{
    padding: 1px;
    margin-top:$content-header-height+$content-states-height+ $content-action-height;
    margin-bottom: $content-action-height;
    
}
