
.history-dropdown {
    min-width: 360px !important;
    max-width: 660px !important;

}

.history-dropdown,
.connection-dropdown,
.nav-user-dropdown {
    padding: 0px !important;
    margin: 0px;
}

.history-title {
    font-size: 14px;
    color: #3d405c;
    text-align: center;
    padding: 8px 0px;
    border-bottom: 1px solid #e3e3e3;
    line-height: 1.5;
    background-color: #fffffe;
}

.history-list-page {
}

.history-list .list-group-item {
    border-radius: 0px;
    padding: 12px;
    margin-top: -1px;
    border-left: transparent;
    border-right: transparent;
}

.history-list .list-group-item.unread {
    z-index: 2;
    color: #3d405c;
    background-color: #f7f7fb;
    border-color: #e1e1e7;
}

.history-list
.list-group-item-action:hover {
    color: #404040;
    text-decoration: none;
    background-color: #f7f7fb;
}

.history-list .list-group-item:last-child {}

.history-info {
  word-break: break-all;
}

.history-info .history-date {
    display: block;
    font-size: 11px;
    margin-top: 4px;
    text-transform: uppercase;
    color: #71748d;
}

.history .dropdown-toggle::after,
.connection .dropdown-toggle::after,
.nav-user .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border: none;
}

.history-list-user-img {
    float: left;
}

.history-list-user-block {
    padding-left: 50px;
    font-size: 14px;
    line-height: 21px;
}

.history-list-user-name {
    color: #5969ff;
    font-size: 14px;
    margin-right: 8px;
}

.list-footer,
.conntection-footer {
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 10px 0px;
    line-height: 1.5;
    font-weight: 700;
    background-color: #5969ff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.list-footer a,
.conntection-footer a {
    color: #fff;
}

.list-footer a:hover,
.conntection-footer a:hover {
    color: #fff;
}




