.warper-spinner{
z-index: 2001;
display: flex;
align-items: center;
position: fixed;
padding: 0px 60px 0px 60px;
left: 0;
top: 0;
width: 100%;
height: 100%;
/* background-color: rgba(0,0,0,.5); */
box-sizing: border-box;
  text-align: center;

    justify-content: center;
}
