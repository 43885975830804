.warper{
z-index: 2001;
position: fixed;
padding: 0px 60px 0px 60px;
left: 0;
top: 0;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,.7);
box-sizing: border-box;
  text-align: center;

    justify-content: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .warper{
    padding: 0px;
  }
  .close{
    display: none;
  }
}
.close{
color: white !important;
position: absolute;
top: 15px;
right: 15px;
font-size: 40px !important;
font-weight: bold !important;
opacity: .2;
cursor: pointer;

}
.content-pdf{
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  text-align: center;
}
.slide{
height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdf{
 max-height: 100%;
  max-width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.page-controls {
    position: absolute;
    bottom: 5%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
}
.page-controls:hover{

    opacity: 100;
}
.page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: inherit;
    font-size: 0.8em;
    border-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
}
