.container-select {
  display: flex;
}
.container-select .form-control {
  width: 80%;
}
.container-action button {
  white-space: nowrap;
}
.assign-user-wrapper{
  position: absolute;
  z-index: 60;
  right: 10px; 
}
