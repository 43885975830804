
.notification-dropdown {
    min-width: 420px !important;
    max-width: 420px !important;

}

.notification-dropdown,
.connection-dropdown,
.nav-user-dropdown {
    padding: 0px !important;
    margin: 0px;
}

.notification-title {
    font-size: 14px;
    color: #3d405c;
    text-align: center;
    padding: 8px 0px;
    border-bottom: 1px solid #e3e3e3;
    line-height: 1.5;
    background-color: #fffffe;
}

.notification-list {
    max-height: 400px;
    overflow-y: auto;
}

.notification-list .list-group-item {
    border-radius: 0px;
    padding: 12px;
    margin-top: -1px;
    border-left: transparent;
    border-right: transparent;
}

.notification-list .list-group-item.unread {
    z-index: 2;
    color: #3d405c;
    background-color: #f7f7fb;
    border-color: #e1e1e7;
}

.notification-list
.list-group-item-action:hover {
    color: #404040;
    text-decoration: none;
    background-color: #f7f7fb;
}

.notification-list .list-group-item:last-child {}

.notification-info {
  word-break: break-word;
  display: flex;
  justify-content: space-between;
}

.notification-info .notification-date {
    display: block;
    font-size: 11px;
    margin-top: 4px;
    text-transform: uppercase;
    color: #71748d;
}

.notification .dropdown-toggle::after,
.connection .dropdown-toggle::after,
.nav-user .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border: none;
}

.notification-list-user-img {
    float: left;
}

.notification-list-user-block {
    padding-left: 50px;
    font-size: 14px;
    line-height: 21px;
}

.notification-list-user-name {
    color: #5969ff;
    font-size: 14px;
    margin-right: 8px;
}

.list-footer,
.conntection-footer {
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding: 10px 0px;
    line-height: 1.5;
    font-weight: 700;
    background-color: #5969ff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.list-footer a,
.conntection-footer a {
    color: #fff;
}

.list-footer a:hover,
.conntection-footer a:hover {
    color: #fff;
}
.indicator-continaer{pointer-events: none;
    position: absolute;
    top: 7px;
    right: 30px;
}
.indicator{    display: inline-block;
    min-width: 1px;
    padding: 2px 6px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    color: white;
    background-color: #DE350B;
}
