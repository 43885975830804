.filecontainer {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}

.fileFieldWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fileViewContainer {
  display: flex;
  align-items: center;
  width: 270px;
  height: 30px;
  padding: 1px;
  border: 1px solid #556ee6;
  margin-left: 1px;
}

.textFieldFile {
  width: 130px;
  line-height: 40px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 4px;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  background: linear-gradient(60deg, #556ee6, #556ee6);
  cursor: pointer;
}
.imagesContainer{

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
}
